/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'style';

// @TODO styles below must to be moved to the mobile ui-style lib

h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--ion-color-text-secondary);
}

h4 {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h2 {
  font-size: 1.875rem;
  line-height: 2.5rem;
}

h1 {
  font-size: 2.375rem;
  line-height: 2.8rem;
}

ion-button {
  --border-radius: 50px;
}

.back-button-class {
  color: var(--ion-color-text-primary);
  border-radius: 50%;
  background: var(--background-light-grey-color);
  height: 40px;
  width: 40px;
}

.back-button-wrapper {
  margin-top: 24px;

  ion-button {
    @extend .back-button-class;
  }
}

ion-modal.modal-default.show-modal:last-of-type {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
