ion-modal {
  &.auth-error {
    --width: fit-content;
    --min-width: 80%;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

    h1 {
      margin: 20px 20px 10px 20px;
    }

    ion-icon {
      margin-right: 6px;

      width: 48px;
      height: 48px;

      padding: 4px 0;

      color: #aaaaaa;
    }

    .wrapper {
      margin-bottom: 10px;
    }
  }
}
